import {PublicClientApplication} from '@azure/msal-browser'
import datamethods from '../services/datamethods.js'
import {mapFields} from 'vuex-map-fields'
export default{
    mixins:[datamethods],
    computed:{
        ...mapFields([
            
            'loggedInUser',
            'loggedIn',
            'adAccount',
            'username',
            'password',
            'token'

        ]),
        
    },
    data(){
        return{
            msalLoggedIn:false,
            msalLoginResponse:null,
            account:null
        }
    },
    methods:{
        async loginmsal(){
            console.log('before pop')
            await this.$msalInstance.loginPopup({})
            console.log('after pop')
            const myAccounts = this.$msalInstance.getAllAccounts();
            this.adAccount = myAccounts[0];
             this.getAccessToken()

/*             await this.$msalInstance
                .loginPopup({})
                .then(() => {
                    console.log('after pop')
                const myAccounts = this.$msalInstance.getAllAccounts();
                this.adAccount = myAccounts[0];
                 this.getAccessToken()
            
                })
                .catch(error => {
                console.error(`error during authentication: ${error}`);
                }); */


           /*  try {
                this.msalLoginResponse=await msalInstance.loginPopup();
                const accessToken = await msalPluginInstance.acquireToken();
         
                this.msalLoggedIn=true
            } catch (error) {
                const authErr = error;
                if(authErr){
                    if(authErr.errorCode=='user_cancelled'){
                        return;
                    }
                }
                console.error({error})
            } */
        },
        async logoutmsal(){
            console.log('logout')
            await this.$msalInstance
                .logout({})
                .then(() => {
                this.$emitter.emit('logout', 'logging out');
                })
                .catch(error => {
                console.error(error);
                });
            /* await msalInstance.logoutPopup();
            this.msalLoggedIn=false; */
        },
        async getAccessToken(){
            let request = {
                scopes: [''],
            };
            const msalInstance = new PublicClientApplication(
                this.$store.state.msalConfig,
            );
            try {
                let tokenResponse = await msalInstance.acquireTokenSilent(request);
                this.token = tokenResponse.accessToken
                //this.$store.commit('setAccessToken', tokenResponse.accessToken);
                this.securitylogin_ad_step(tokenResponse.accessToken)
            } catch (error) {
                console.error( 'Silent token acquisition failed. Using interactive mode' );
                let tokenResponse = await msalInstance.acquireTokenPopup(request);
                console.log('Access token acquired via interactive auth') // ${tokenResponse.accessToken}`)
                //this.$store.commit('setAccessToken',tokenResponse.accessToken);
                this.token = tokenResponse.accessToken
                this.securitylogin_ad_step(tokenResponse.accessToken)
            }
        },
        async securitylogin_ad_step(token){
            
            console.log('security login ad 1' )
            //add to api
            this.AddTokenToAPI(token)

            let req = {
                username:this.adAccount.username,
                password:this.password
            }
            this.pleaseWait=true;
            //console.log('login2')

            
            var response = await this.callAPI(req,'securitylogin')
            //handle response
            if(response.Successful){
                this.securitylogin_ad(response.securityInfo.token)
                
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
  
        },
        async securitylogin_ad(token){
            
            console.log('security login ad 2 ' )
            //add to api
            this.AddTokenToAPI(token)
            this.token=token
            let req = {
                email:this.adAccount.username,
                password:this.password
            }
            this.pleaseWait=true;
            //console.log('login2')

            
            var response = await this.callAPI(req,'securityloginuser')
            //handle response
            if(response.Successful){
                
                this.loggedInUser=response.SecurityUser; //store user
                this.AddUserToAPI(this.loggedInUser)
                this.loggedIn=true;
                
                this.getSecurityAppItemList()
                this.$emit('loggedin')
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
  
        },
    },
    async created(){
        console.log('before create msal')
        this.$msalInstance = new PublicClientApplication(this.$store.state.msalConfig)
      },
      mounted(){
        if(window.USE_AD_SECURITY){
            console.log('before get accts')
            const accounts = this.$msalInstance.getAllAccounts();
            console.log('after get accts')
            if(accounts.length==0){
                console.log('no accts')
                return;
            }
            this.adAccount = accounts[0];
            this.getAccessToken()
            
        }
        
      },

}