<template>
  <div class="loginDiv">
        <div v-if="didFailAuth" class="bottom10">
            <label class="responsemessage">You have been securely logged out. Please login again to continue.</label>
        </div>
        <div >
            <form ref="loginform" v-on:keyup.enter="login">
                <div v-if="!useADSecurity">
                    <formentrystacked inputid="user" label="email" v-model="username" />
                    <formentrystacked inputid="pass" inputtype="password" label="password" v-model="password" />
                
                    <smallbutton @click="login()" toplabel="Login"/>
                    <div class="margin10">
                        <linkbutton @click="forgotpassword()" toplabel="forgot password"/>
                    </div>
                </div>
                
                <div v-if="useADSecurity">
                    <smallbutton @click="startloginmsal()" toplabel="Login with Active Directory"/>
                </div>
                
                <div v-if="pleaseWait">
                    <label for="" class="pleasewait">Please Wait...</label>
                </div> 
            </form>
            
        </div>
            
        <!-- GOOGLE -->
     <!--    <div v-if="isGoogle">
            <googleidentity @loggedin="googleloggedin" @loginfailed="googlefailed"/>
        </div> -->
        <div class="horizFlex " v-if="allowStoreLogin">
            <input class="entryCheckbox" type="checkbox" v-model="storeLogin" >
            <label for="" class="mediumtext">Keep Me Logged In</label>
        </div>
        <div>
            <label class="responsemessage">{{responseMessage}}</label>
        </div>
        


      <updatepassword ref="changepass" v-show="ischangepassword" :username="username" :oldpassword="oldpassword"></updatepassword>
    </div>

        
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import securityhelper from '../../services/securityhelper.js'
import adhelper from '../../services/adhelper'
import datamethods from '../../services/datamethods.js'
import formentrystacked from '../controls/entry-stacked.vue'
import linkbutton from '../controls/linkbutton.vue'
import smallbutton from '../controls/button.vue'
import updatepassword from './updatepassword.vue'
//import googleidentity from './googleidentity.vue'
export default {
    name:'loginuser',
    mixins:[datamethods, securityhelper,adhelper],
    components:{
        formentrystacked,
        linkbutton,
        smallbutton,
        updatepassword,
        //googleidentity
    },
    computed:{
        ...mapFields([
            'loggedInUser',
            'loggedIn',
            'username',
            'oldpassword',
            'isReady',
            'didFailAuth',
            'password',
            'token'
        ]),
        useAppSecurity(){
            if(window.USE_APP_SECURITY==undefined){return false}
            else{return window.USE_APP_SECURITY}
        },
        useADSecurity(){
            if(window.USE_AD_SECURITY==undefined){return false}
            else{return window.USE_AD_SECURITY}
          }
    },
    data(){
        return{
            newUserURL:window.NEW_USER_URL,
            newUserText:'Click here to create a new Account',
           

            pleaseWait:false,
            responseMessage:'',

            ischangepassword:false,

            //GOOGLE
            //isGoogle:window.GOOGLE_LOGIN!="",

            allowStoreLogin:true,
            storeLogin:false,
            isMountedLogin:true
        }
    },
    mounted(){
    
    
  },
    methods:{
        startloginmsal(){
            var logged = this.loginmsal()
            if(logged){this.login()}
        },
        prelogin(){
            if(this.allowStoreLogin){
                this.token = localStorage.token
                //console.log('t' + this.token)
                if(this.token==null){
                    this.setready()
                    return 
                }
                if(this.token=='' || this.token=='null'){
                    this.setready()
                    return
                }

                //now we call our login, with the token! (no username or pwd)
                this.post_login(this.token)
            }
            else{
                this.setready()
            }
        },
        setready(){
            this.isReady=true
        },
        changepassword(){
            this.ischangepassword=true
            this.$refs.changepass.ChangePassword();
        },
        async forgotpassword(){
            
            //validate
            if(this.username==""){
                this.responseMessage="Please enter your email address and click forgot password"
                return
              }
            let req = {
                email:this.username,
            }
            this.pleaseWait=true;
            this.responseMessage='';
            var response = await this.callAPI(req,'forgotpass_appurl')
            //handle response
            if(response.Successful){
                 this.responseMessage = "Please check your email for your password reset link"
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;    
        },
   
        async login(){
            


            this.pleaseWait=true
            this.responseMessage='';
            //validate
            if(this.username=="" || this.password==""){this.pleaseWait=false; return}
            let req = {
                username:this.username,
                password:this.password,
            }
            var response = await this.callAPI(req,'securitylogin')
            //handle response
            if(response.Successful){
                this.post_login(response.securityInfo.token)
            }else{
                this.responseMessage = response.Message
            }
           this.pleaseWait=false
        },
        logout(){
            if(this.useADSecurity){
                console.log('before logut')
                this.logoutmsal()
                this.loggedIn=false
            }
            
        },
        //GOOGLE HANDLING
        googleloggedin(token){
            this.post_login(token)
        },
        googlefailed(message){
            this.responseMessage =message
        },
        async post_login(token){
            if(this.useAppSecurity){
                this.securitypost_login(token)
                return;
            }


            //console.log('t' + token)
            this.token=token
            //add to api
            this.AddTokenToAPI(token)

            //store?
            if(this.storeLogin){
                localStorage.token=token;
            }

            //no username/pwd
            this.responseMessage='';
            //validate
            var response = await this.callAPI(null,'adminlogin')
            //handle response
            if(response.Successful){
                console.log(response)
                this.loggedInUser= response.Person
                this.loggedIn=true
                this.didFailAuth=false
                this.$emit('loggedin')
                //this.AddUserToAPI(this.loggedInUser)
               this.setready()
            }else{
               this.loggedIn=false
                //don't show the message on the mounted login
                if(this.isMountedLogin){
                    this.isMountedLogin=false
                }else{
                    this.responseMessage = response.Message
                }
                this.setready()
            }
        }
       
    }
}
</script>

<style >

.loginDiv{
  margin-top:60px;
  grid-area: center;
  display:flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size:large;
}
.margin10{
    margin:10px;
}
.responsemessage{
    color:red;
}
</style>


