//import Vue from 'vue'
//import Vuex from 'vuex'

import { createStore } from 'vuex'
import {getField, updateField} from 'vuex-map-fields'

//Vue.use(Vuex)
//export const store = new Vuex.Store({
export const store = createStore({
    state:{
        securityAppName:'AdminHome',
        securityAppItems:[],

        //general
        username:'',
        oldpassword:'',
        password:'',
        token:null,
        loggedInUser:{
            name:'User',
            user_personId:0,
            LocationId:0
        },
        adAccount:null,
        loggedIn:false,
        robot:true,
    
 
       
        
        pleaseWait:false,
        responseMessage:'',

        msalConfig:{
            auth:{
                clientId:window.AD_CLIENTID,
                authority:window.AD_AUTHORITY
            },
            cache:{
                cacheLocation:'localStorage',
            },
            accessToken:''
        }
    },
    mutations:{
        updateField,
        resetbeforeapi(state){
            state.pleaseWait=true;
            state.responseMessage=''
        },
    },
    getters:{
        getField,
    },
    actions:{
        beforeAPI(context){
            context.commit('resetbeforeapi'); 
        },
        
    }
})